import { Suspense, useState, useEffect, useRef } from 'react'

import styled from 'styled-components'
import Select from 'react-select'
import memoizeOne from 'memoize-one'
import dayjs from 'dayjs'
import 'dayjs/locale/cs'
dayjs.locale('cs')

import { httpGet, wrapPromise } from '@lib/utils'
import { Loading } from '../../shared'

import { Title, Row, Label, Value } from '../atoms'
import InstancesColumn from './InstancesColumn'

const CallsReport = props => {
  const resource = fetchData()

  return (
    <>
      <Suspense fallback={<Loading />}>
        <Calls
          clients={resource.clients}
          instances={resource.instances}
          products={resource.products}
          issues={resource.issues}
        />
      </Suspense>
    </>
  )
}

export default CallsReport

const Calls = props => {
  const clients = props.clients.read()
  const instances = props.instances.read()
  const products = props.products.read()
  const issues = props.issues.read()

  const [year, setYear] = useState({ label: dayjs().format("YYYY"), value: dayjs().startOf("year") })
  const yearsRow = useRef(null);
  const yearsRow2 = useRef(null);

  let yearOptions = []

  for (let i = 0; i < 5; i++) {
    yearOptions.push({ label: dayjs().subtract(i, "year").format("YYYY"), value: dayjs().subtract(i, "year").startOf("year") })
  }

  useEffect(() => {
    const position = {
      left: (dayjs().month() - 2) * 200,
      behavior: 'smooth',
    }

    yearsRow.current.scrollTo(position)
    yearsRow2.current.scrollTo(position)
  })

  const data = memoizedMergeData(year, clients, instances, products, issues)

  return (
    <Report>
      <>
        <Title>Leady</Title>
        <Row>
          <Label>Rok:</Label>
          <Value>
            <Select options={yearOptions} value={year} onChange={o => setYear(o)} />
          </Value>
        </Row>
        <YearsRow ref={yearsRow}>
          {[1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12].map(month => (
            <InstancesColumn
              key={month}
              title={dayjs().month(month - 1).format("MMMM")}
              data={data.filter(i => dayjs(i.created_at).month() == month - 1 && (!i.client.order.tariff || i.client.order.tariff.name == "Trial"))}
              dataType="created"
            />
          ))}
        </YearsRow>
      </>
      <>
        <Title>Noví klienti</Title>
        <YearsRow ref={yearsRow2}>
          {[1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12].map(month => (
            <InstancesColumn
              key={month}
              title={dayjs().month(month - 1).format("MMMM")}
              data={data.filter(i => dayjs(i.created_at).month() == month - 1 && i.client.order.tariff && i.client.order.tariff.name != "Trial")}
              dataType="ordered"
            />
          ))}
        </YearsRow>
      </>
    </Report>
  )
}

function fetchClients() {
  return httpGet('/clients.json')
}

function fetchProducts() {
  return httpGet('/admin/products.json')
}

function fetchInstances() {
  return httpGet('/instances.json')
}

function fetchIssues() {
  return httpGet("/issues.json?crm=1")
}

function fetchData() {
  const clients = fetchClients()
  const products = fetchProducts()
  const instances = fetchInstances()
  const issues = fetchIssues()

  return {
    clients: wrapPromise(clients),
    products: wrapPromise(products),
    instances: wrapPromise(instances),
    issues: wrapPromise(issues),
  }
}

const memoizedMergeData = memoizeOne(mergeData)

function mergeData(year, clients, instances, products, issues) {
  let productsCollection = {}
  products.forEach(p => (productsCollection[p.id] = p))

  const clientsCollection = clients.reduce((acc, client) => {
    acc[client.id] = client
    return acc
  }, {})

  const issuesCollection = issues.reduce((acc, issue) => {
    acc[issue.id] = issue
    return acc
  }, {})

  const filtered = instances.filter(i => dayjs(i.created_at) >= year.value && dayjs(i.created_at) <= year.value.endOf("year"))

  return filtered.map(i => {
    const client = clientsCollection[i.client_id]
    const issue = issuesCollection[client.crm_client_card_id]
    const activeOrder = client?.orders.find(o => o.status == 'running')
    const order = activeOrder
      ? {
          billingPeriod: activeOrder.billing_period,
          status: activeOrder.status,
          productId: activeOrder.product_id,
          tariff: productsCollection[activeOrder.product_id],
          createdAt: activeOrder.created_at,
        }
      : {}

    return {
      client: {
        ...client,
        order,
        issue,
      },
      ...i,
    }
  })
}

export const YearsRow = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: flex-start;
  margin-bottom: 5rem;
  gap: 1rem;

  overflow-x: scroll;
`

export const Report = styled.div`
  margin-bottom: 10rem;
`
