import { CRMBtn } from './atoms'

export const getColumns = ({
  industries,
  companySizes,
  instanceDataKeys,
  products,
  defaultTariffFilter,
}) => {
  const lookupSizes = companySizes
  const tariffFilter = defaultTariffFilter.map(i => `${i}`)

  let lookupIndustries = {}
  industries.forEach(i => (lookupIndustries[i.id] = i.name))

  const lookupCountries = { CS: 'Česko', SK: 'Slovensko' }

  let lookupProducts = {}
  products.forEach(p => (lookupProducts[p.id] = p.name))

  let lookupBillingPeriod = {}
  const periods = [1, 3, 6, 12]
  periods.forEach(i => (lookupBillingPeriod[i] = i))

  const lookupBoolean = { 1: 'Ano', 0: 'Ne' }

  const defaultHiddenProps = { hidden: true, searchable: false, grouping: false }
  const defaultInstanceDataProps = { ...defaultHiddenProps, grouping: true }
  const limitWidthProps = {
    headerStyle: { width: '200px', maxWidth: '200px' },
    filterCellStyle: { width: '200px', maxWidth: '200px' },
    cellStyle: {
      width: '200px',
      maxWidth: '200px',
      whiteSpace: 'nowrap',
      overflow: 'hidden',
      textOverflow: 'ellipsis',
    },
  }

  const days = 14
  const visibleKeys = [`Last Seen ${days}`, `Projects Created ${days}`, `Journal Details ${days}`]

  const stats = instanceDataKeys.keys.map(key => ({
    title: key,
    field: key,
    ...defaultInstanceDataProps,
    hidden: !visibleKeys.includes(key),
    customFilterAndSearch: (term, rowData) =>
      term > 0 ? rowData[key] >= term : rowData[key] <= Math.abs(term),
  }))

  return [
    {
      title: 'Firma',
      field: 'company',
      cellStyle: {
        fontWeight: 'bold',
        whiteSpace: 'nowrap',
        overflow: 'hidden',
        textOverflow: 'ellipsis',
      },
      render: rowData => {
        return (
          <span>
            {rowData.company}{' '}
            <span style={{ float: 'right' }}>
              <CRMBtn cardId={rowData.crm_client_card_id} />
            </span>
          </span>
        )
      },
    },
    {
      title: 'Velikost',
      field: 'company_size',
      grouping: true,
      searchable: false,
      lookup: lookupSizes,
    },
    {
      title: 'Země',
      field: 'country',
      grouping: true,
      searchable: false,
      lookup: lookupCountries,
      hidden: true,
    },
    {
      title: 'Odvětví',
      field: 'industry_id',
      grouping: true,
      searchable: false,
      hidden: true,
      lookup: lookupIndustries,
      ...limitWidthProps,
    },

    {
      title: 'Instance id',
      field: 'orderInstanceId',
      grouping: false,
      searchable: false,
      hidden: true,
    },
    {
      title: 'Tarif',
      field: 'orderProductId',
      grouping: true,
      searchable: false,
      lookup: lookupProducts,
      defaultFilter: tariffFilter,
      ...limitWidthProps,
    },
    {
      title: 'Fakturační období',
      field: 'orderBillingPeriod',
      grouping: true,
      searchable: false,
      lookup: lookupBillingPeriod,
      hidden: true,
    },
    {
      title: 'Tarif od',
      field: 'orderCreatedAt',
      grouping: false,
      searchable: false,
      hidden: true,
      type: 'date',
      filtering: false,
    },
    {
      title: 'Zablokováno',
      field: 'blockedAt',
      grouping: false,
      searchable: true,
      hidden: true,
      type: 'date',
      filtering: true,
    },
    {
      title: 'Zablokováno Ano/Ne',
      field: 'isBlocked',
      grouping: true,
      hidden: false,
      filtering: true,
      lookup: lookupBoolean,
    },
    {
      title: 'Předplatné zrušeno',
      field: 'subscriptionCanceledAt',
      grouping: false,
      hidden: true,
      type: 'date',
      filtering: false,
    },
    {
      title: 'Aktivní předplatné?',
      field: 'hasActiveSubscription',
      grouping: true,
      hidden: false,
      filtering: true,
      lookup: lookupBoolean,
    },
    {
      title: 'Je ve Faktoroidu?',
      field: 'hasFakturoidId',
      grouping: true,
      hidden: false,
      filtering: true,
      lookup: lookupBoolean,
    },
    {
      title: 'Faktoroid Id',
      field: 'fakturoid_id',
      grouping: true,
      hidden: true,
      filtering: true,
    },
    {
      title: 'Trvání předplatného',
      field: 'orderDuration',
      grouping: true,
      hidden: true,
      filtering: true,
    },
    {
      title: 'LTV',
      field: 'ltv',
      grouping: true,
      hidden: true,
      filtering: true,
    },
    {
      title: 'Atyp',
      field: 'atypical',
      lookup: { true: 'Ano', false: 'Ne' },
      ...defaultHiddenProps,
    },
    {
      title: 'Newsletter',
      field: 'newsletter',
      lookup: { true: 'Ano', false: 'Ne' },
      ...defaultHiddenProps,
    },
    { title: 'Email', field: 'email', ...defaultHiddenProps },
    { title: 'Phone', field: 'phone', ...defaultHiddenProps },
    { title: 'Ulice', field: 'street', ...defaultHiddenProps },
    { title: 'Město', field: 'city', ...defaultHiddenProps },
    { title: 'PSČ', field: 'zip', ...defaultHiddenProps },
    { title: 'IČ', field: 'ic', ...defaultHiddenProps },
    { title: 'DIČ', field: 'dic', ...defaultHiddenProps },
    { title: 'Status', field: 'orderStatus', ...defaultHiddenProps },
    ...stats,
  ]
}
