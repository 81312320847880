import { useState, Suspense } from 'react'
import { filter as _filter, each, map } from 'lodash'
import memoizeOne from 'memoize-one'
import Select from 'react-select'

import { httpGet, wrapPromise } from '@lib/utils'
import { Loading } from '../shared'

import OrdersGraph from './OrdersGraph'

const InstancesReport = props => {
  const resource = fetchData()

  return (
    <>
      <Suspense fallback={<Loading />}>
        <Instances
          clients={resource.clients}
          instances={resource.instances}
          products={resource.products}
          orders={resource.orders}
        />
      </Suspense>
    </>
  )
}

export default InstancesReport

const Instances = props => {
  const clients = props.clients.read()
  const products = props.products.read()
  const instances = props.instances.read()
  const orders = props.orders.read()

  return (
    <>
      <h4>Instance</h4>

      <div>
        <OrdersGraph clients={clients} instances={instances} orders={orders} products={products} />
      </div>
    </>
  )
}

function fetchClients() {
  return httpGet('/clients.json?with_deleted=1')
}

function fetchProducts() {
  return httpGet('/admin/products.json')
}

function fetchInstances() {
  return httpGet('/instances.json?with_deleted=1')
}

function fetchOrders() {
  return httpGet('/orders.json')
}

function fetchData() {
  const clients = fetchClients()
  const products = fetchProducts()
  const instances = fetchInstances()
  const orders = fetchOrders()

  return {
    clients: wrapPromise(clients),
    products: wrapPromise(products),
    instances: wrapPromise(instances),
    orders: wrapPromise(orders),
  }
}
