import { Suspense, useState, useEffect, useRef } from 'react'

import styled from 'styled-components'
import memoizeOne from 'memoize-one'
import dayjs from 'dayjs'
import 'dayjs/locale/cs'
dayjs.locale('cs')

import { differenceBy, remove, debounce, take } from 'lodash'

import { FunnelChart, Tooltip, Funnel, LabelList } from 'recharts'
import InstanceDataGraph from '../InstanceDataGraph'

import { httpGet, wrapPromise, formatDate, formatDateForInput } from '@lib/utils'
import { Loading } from '../../shared'

import { Subtitle, NavPills, NavPill, Label, Value, Table, FloatRight, CRMBtn } from '../atoms'

const AarrrReport = props => {
  const twoMonthsAgo = dayjs().subtract(2, 'month').startOf('month').unix() * 1000
  const [from, setFrom] = useState(twoMonthsAgo)
  const [to, setTo] = useState(null)
  const [resource, setResource] = useState(null)

  useEffect(() => {
    setResource(fetchData(from, to))
  }, [from, to])

  if (resource === null) {
    return <Loading />
  }

  return (
    <>
      <Suspense fallback={<Loading />}>
        <Aarrr
          aarrr={resource.aarrr}
          instances={resource.instances}
          clients={resource.clients}
          products={resource.products}
          from={from}
          to={to}
          setFrom={debounce(setFrom, 2000)}
          setTo={debounce(setTo, 2000)}
        />
      </Suspense>
    </>
  )
}

export default AarrrReport

const Aarrr = props => {
  const aarrr = props.aarrr.read()
  const instancesSource = props.instances.read()
  const clients = props.clients.read()
  const products = props.products.read()

  const instances = memoizedMergeData(clients, instancesSource, products)

  const [source, setSource] = useState('all')
  const [selectedIndex, setSelectedIndex] = useState(null)
  const [selectedInstances, setSelectedInstances] = useState([])

  const changeSource = type => {
    setSource(type)
  }

  const { acquisition: acquisitionIds, no_login: noLoginIds, activation: activationIds, retention: retentionIds, revenue: revenueIds, referral: referalIds } = aarrr

  let noLogin = instances.filter(i => noLoginIds.includes(i.id))
  let acquisition = instances.filter(i => acquisitionIds.includes(i.id))
  let activation = instances.filter(i => activationIds.includes(i.id))
  let retention = instances.filter(i => retentionIds.includes(i.id))
  let revenue = instances.filter(i => revenueIds.includes(i.id))
  let referral = instances.filter(i => referalIds.includes(i.id))

  /*
  *   Filter data
  */
  if (['cs', 'sk'].includes(source)) {
    noLogin = noLogin.filter(i => i.language == source)
    acquisition = acquisition.filter(i => i.language == source)
    activation = activation.filter(i => i.language == source)
    retention = retention.filter(i => i.language == source)
    revenue = revenue.filter(i => i.language == source)
    referral = referral.filter(i => i.language == source)
  } else if (source == 'affil') {
    noLogin = noLogin.filter(i => i.referral != null)
    acquisition = acquisition.filter(i => i.referral != null)
    activation = activation.filter(i => i.referral != null)
    retention = retention.filter(i => i.referral != null)
    revenue = revenue.filter(i => i.referral != null)
    referral = referral.filter(i => i.referral != null)
  }

  const noLoginAll = [...noLogin, ...acquisition, ...activation, ...retention, ...revenue, ...referral]
  const acquisitionAll = [...acquisition, ...activation, ...retention, ...revenue, ...referral]
  const activationAll = [...activation, ...retention, ...revenue, ...referral]
  const retentionAll = [...retention, ...revenue, ...referral]
  const revenueAll = [...revenue, ...referral]
  const referralAll = referral

  const funnelData = [
    {
      value: noLoginAll.length,
      all: noLoginAll,
      onlyIn: noLogin,
      name: "Nepřihlášení",
      fill: "#8884d8",
      textColor: "white",
    },
    {
      value: acquisitionAll.length,
      all: acquisitionAll,
      onlyIn: acquisition,
      name: "Acquisition",
      fill: "#8884d8",
      textColor: "white",
    },
    {
      value: activationAll.length,
      all: activationAll,
      onlyIn: activation,
      name: "Activation",
      fill: "#83a6ed",
      textColor: "white",
    },
    {
      value: retentionAll.length,
      all: retentionAll,
      onlyIn: retention,
      name: "Retention",
      fill: "#8dd1e1",
      textColor: "#666",
    },
    {
      value: revenueAll.length,
      all: revenueAll,
      onlyIn: revenue,
      name: "Revenue",
      fill: "#82ca9d",
      textColor: "#666",
    },
    {
      value: referralAll.length,
      all: referralAll,
      onlyIn: referral,
      name: "Referral",
      fill: "#a4de6c",
      textColor: "#666",
    },
    {
      value: referralAll.length,
      all: referralAll,
      onlyIn: referral,
      name: "",
      fill: "white",
      textColor: "#666",
    },
  ]

  const handleClick = (data, index) => {
    setSelectedIndex(index)
    setSelectedInstances([])
  };

  const updateSelectedInstances = id => {
    let newAr = [...selectedInstances]

    if (newAr.includes(id)) {
      remove(newAr, i  => i == id)
    } else {
      newAr.push(id)
    }

    setSelectedInstances(newAr)
  }

  const _renderInstanceRow = i => {
    return (
      <tr key={i.id}>
        <td>
          <Name active={selectedInstances.includes(i.id)} onClick={() => updateSelectedInstances(i.id)}>
            {i.name}
            <FloatRight><CRMBtn cardId={i.client?.crm_client_card_id} /></FloatRight>
          </Name>
        </td>
        <td className="Table__date">{formatDate(i.created_at)}</td>
        <td className="Table__date">{i.blocked_at && formatDate(i.blocked_at, { short: true })}</td>
        <td className="Table__date">{i.deleted_at && formatDate(i.deleted_at, { short: true })}</td>
      </tr>
    )
  }

  const _renderDelimiter = (label, count, backgroundColor="#eee", textColor="#666") => {
    return (
      <tr key={label} className="Table__delimiter">
        <td colSpan="4"><div style={{ backgroundColor, color: textColor }}>{label}: {count}</div></td>
      </tr>
    )
  }

  const renderDetails = () => {
    const phase = funnelData[selectedIndex]
    const onlyInIds = phase.onlyIn.map(i => i.id)

    const diff = differenceBy(phase.all, phase.onlyIn, 'id')
    const active = diff.filter(i => i.status == 'active')
    const inactive = diff.filter(i => i.status == 'deleted')

    const graphIds = take(selectedInstances.length > 0 ? selectedInstances : onlyInIds, 100).join(',')

    return (
      <div className="row">
        <div className="col-lg-6 col-md-6">
          <Subtitle>{phase.name}</Subtitle>
          Vše: {phase.value}<br />
          Pouze v této fázi: {phase.onlyIn.length}<br />
          <br />

          <Table>
            <thead>
              <tr>
                <th>Instance</th>
                <th>Vytvořena</th>
                <th>Zablokováno</th>
                <th>Smazáno</th>
              </tr>
            </thead>
            <tbody>
              {_renderDelimiter('Jen tato fáze', phase.onlyIn.length, phase.fill, phase.textColor)}
              {phase.onlyIn.map(i => _renderInstanceRow(i))}

              {_renderDelimiter('V dalších fázích', active.length)}
              {active.map(i => _renderInstanceRow(i))}

              {_renderDelimiter('Smazáno', inactive.length)}
              {inactive.map(i => _renderInstanceRow(i))}
            </tbody>
          </Table>
        </div>
        <div className="col-lg-6 col-md-6">
          <Subtitle>{phase.name}</Subtitle>
          <InstanceDataGraph
            instanceId={graphIds}
            name={"Journal Details 7"}
            instances={phase.all}
            recalculateForAARRR={true}
          />
          <InstanceDataGraph
            instanceId={graphIds}
            name={"Last Seen 7"}
            instances={phase.all}
            recalculateForAARRR={true}
          />
          <InstanceDataGraph
            instanceId={graphIds}
            name={"Tasks Created 7"}
            instances={phase.all}
            recalculateForAARRR={true}
          />
          <InstanceDataGraph
            instanceId={graphIds}
            name={"Projects Created 7"}
            instances={phase.all}
            recalculateForAARRR={true}
          />
        </div>
      </div>
    )
  }

  return (
    <Report>
      <NavPills>
        <NavPill active={source == 'all' && 'active'} onClick={() => changeSource('all')}>Vše</NavPill>
        <NavPill active={source == 'cs' && 'active'} onClick={() => changeSource('cs')}>CZ</NavPill>
        <NavPill active={source == 'sk' && 'active'} onClick={() => changeSource('sk')}>SK</NavPill>
        <NavPill active={source == 'affil' && 'active'} onClick={() => changeSource('affil')}>Od partnerů</NavPill>

        <Label>Od:</Label>
        <Value>
          <input
            type="date"
            value={props.from ? formatDateForInput(props.from) : ''}
            onChange={e => e.target.value ? props.setFrom(e.target.valueAsNumber) : props.setFrom(null)}
            max={props.to ? formatDateForInput(props.to) : ''}
          />
        </Value>
        <Label>Do:</Label>
        <Value>
          <input
            type="date"
            value={props.to ? formatDateForInput(props.to) : ''}
            onChange={e => e.target.value ? props.setTo(e.target.valueAsNumber) : props.setTo(null)}
            min={props.from ? formatDateForInput(props.from) : ''}
          />
        </Value>
      </NavPills>
      <div className="row">
        <div className="col-lg-12">
          <FunnelWrap>
            <RotateChart>
              <FunnelChart
                width={400}
                height={800}
                margin={{
                  top: 5,
                  right: 40,
                  bottom: 15,
                  left: 30
                }}
              >
                <Tooltip />
                <Funnel
                  dataKey="value"
                  data={funnelData}
                  isAnimationActive
                  onClick={handleClick}
                  cursor="pointer"
                >
                  <LabelList
                    position="inside"
                    fill="#000"
                    stroke="none"
                    dataKey="name"
                  />
                </Funnel>
              </FunnelChart>
          </RotateChart>
          </FunnelWrap>
        </div>
      </div>
      {selectedIndex !== null && renderDetails()}
    </Report>
  )
}

function fetchAarrr(from = null, to = null) {
  const fromStr = from ? `&from=${formatDateForInput(from)}` : ''
  const toStr = to ? `&to=${formatDateForInput(to)}` : ''
  return httpGet(`/instance_data/aarrr.json?f=1${fromStr}${toStr}`)
}

function fetchInstances(from = null, to = null) {
  const fromStr = from ? `&from=${formatDateForInput(from)}` : ''
  const toStr = to ? `&to=${formatDateForInput(to)}` : ''
  return httpGet(`/instances.json?with_deleted=1${fromStr}${toStr}`)
}

function fetchClients() {
  return httpGet('/clients.json')
}

function fetchProducts() {
  return httpGet('/admin/products.json')
}

function fetchData(from = null, to = null) {
  const aarrr = fetchAarrr(from, to)
  const instances = fetchInstances(from, to)
  const clients = fetchClients()
  const products = fetchProducts()

  return {
    aarrr: wrapPromise(aarrr),
    instances: wrapPromise(instances),
    clients: wrapPromise(clients),
    products: wrapPromise(products),
  }
}

const memoizedMergeData = memoizeOne(mergeData)

function mergeData(clients, instances, products) {
  const productsCollection = products.reduce((acc, p) => { acc[p.id] = p; return acc }, {})
  const clientsCollection = clients.reduce((acc, c) => {
      const activeOrder = c.orders.find(o => o.status == 'running')

      if (activeOrder?.instance_id) {
        activeOrder.product = productsCollection[activeOrder.product_id]
        acc[activeOrder.instance_id] = {
          activeOrder,
          ...c,
        }
      }

      return acc;
    }, {})

  return instances.map(i => {
    const client = clientsCollection[i.id]

    return {
      ...i,
      client,
    }
  })
}

export const Report = styled.div`
  margin-bottom: 10rem;
`

export const FunnelWrap = styled.div`
  height: 320px;
  position: relative;
  overflow: hidden;
`

export const RotateChart = styled.div`
  width: 600px;
  height: 800px;
  transform: rotate(270deg);
  transform-origin: center center;
  position: absolute;
  top: -400px;
  left: 25%;

  .recharts-label-list text {
    tspan {
      fill: black;
    }
  }

  .recharts-default-tooltip {
    transform: rotate(90deg);
    transform-origin: center center;
  }
`

export const Name = styled.div`
  cursor: pointer;
  font-weight: ${props => (props.active ? 'bold' : 'normal')};

  &:hover {
    text-decoration: underline;
  }
`
