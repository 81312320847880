import { useState } from 'react'
import Select from 'react-select'
import styled from 'styled-components'
import map from 'lodash/map'
import orderBy from 'lodash/orderBy'
import filter from 'lodash/filter'
import take from 'lodash/take'
import takeRight from 'lodash/takeRight'

import { httpGet, wrapPromise } from '@lib/utils'

import { Loading } from '../shared'

import InstanceDataGraph from './InstanceDataGraph'

const ClientsTrendsGraph = props => {
  const { instances, instanceDataKeys, instanceData } = props

  const [key, setKey] = useState({ label: 'Last Seen 14', value: 'Last Seen 14' })
  const dataKeysOptions = instanceDataKeys.keys.map(k => ({ label: k, value: k }))

  return (
    <>
      <Group>
        <Label>Klíč:</Label>
        <Value>
          <Select options={dataKeysOptions} value={key} onChange={item => setKey(item)} />
        </Value>
      </Group>
      <Graph>
        {key.value && (
          <InstanceDataGraph
            name={key.value}
            height={300}
            instances={instances}
            instanceId={props.instanceId}
          />
        )}
      </Graph>
    </>
  )
}

export default ClientsTrendsGraph

const Group = styled.div`
  display: flex;
  flex-direction: row;
  cursor: pointer;
  justify-content: flex-start;
  align-items: center;
`

const Label = styled.div`
  display: flex;
  margin-right: 1rem;
  font-weight: bold;
`

const Value = styled.div`
  display: block;
  min-width: 30rem;
  margin-right: 1.5rem;
`

const Graph = styled.div`
  margin-top: 2rem;
`
