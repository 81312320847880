import { useState, Suspense } from 'react'
import { filter as _filter, each, map } from 'lodash'
import memoizeOne from 'memoize-one'
import Select from 'react-select'

import { httpGet, wrapPromise } from '@lib/utils'
import { Loading } from '../shared'

import ClientsBySizeChart from './ClientsBySizeChart'
import ClientsByTariffChart from './ClientsByTariffChart'
import ClientsByIndustryChart from './ClientsByIndustryChart'
import ClientsTable from './ClientsTable'
import ClientsBaseStatsGraph from './ClientsBaseStatsGraph'
import ClientsTrendsGraph from './ClientsTrendsGraph'

const ClientsReport = props => {
  const resource = fetchData()

  return (
    <>
      <Suspense fallback={<Loading />}>
        <Clients
          clients={resource.clients}
          instances={resource.instances}
          industries={resource.industries}
          instanceDataKeys={resource.instanceDataKeys}
          instanceData={resource.instanceData}
          products={resource.products}
        />
      </Suspense>
    </>
  )
}

export default ClientsReport

let persitentFilter = {}

const Clients = props => {
  const clients = props.clients.read()
  const industries = props.industries.read()
  const instanceDataKeys = props.instanceDataKeys.read()
  const instanceData = props.instanceData.read()
  const products = props.products.read()
  const instances = props.instances.read()

  const defaultTariffFilter = [1, 2, 4, 5, 6, 7, 8, 9]

  const [filter, setFilter] = useState({
    orderProductId: c => defaultTariffFilter.includes(c.orderProductId),
  })

  const [pieChartType, setPieChartType] = useState({ label: 'Tarif', value: 'tariff' })

  persitentFilter = filter

  const updateFilter = (name, value) => {
    let newFilter = { ...persitentFilter }

    if (!Boolean(value) || (Array.isArray(value) && value.length == 0)) {
      delete newFilter[name]
    } else {
      const valueInt = parseInt(value)

      if (instanceDataKeys.keys.includes(name) && !isNaN(valueInt)) {
        newFilter[name] = client =>
          valueInt > 0 ? client[name] >= valueInt : client[name] <= Math.abs(valueInt)
      } else {
        newFilter[name] = value
      }
    }

    persitentFilter = newFilter
    setFilter(newFilter)
  }

  const data = memoizedMergeData(clients, instances, instanceData, products)
  const filtered = memoizedFilterData(data, filter)

  const pieChartTypeOptions = [
    { label: 'Tarif', value: 'tariff' },
    { label: 'Velikost', value: 'size' },
    { label: 'Odvětví', value: 'industry' },
  ]

  return (
    <>
      <h4>Počet: {filtered.length}</h4>
      <div className="row">
        <div className="col-xs-6 col-lg-6">
          {pieChartType.value == 'tariff' && <ClientsByTariffChart clients={filtered} />}
          {pieChartType.value == 'size' && <ClientsBySizeChart clients={filtered} />}
          {pieChartType.value == 'industry' && <ClientsByIndustryChart clients={filtered} industries={industries} />}

          <div style={{ width: '15rem', margin: '0 auto' }}>
            <Select options={pieChartTypeOptions} value={pieChartType} onChange={i => setPieChartType(i)} />
          </div>
        </div>
        <div className="col-xs-12 col-lg-6">
          <ClientsBaseStatsGraph clients={filtered} instanceDataKeys={instanceDataKeys} />
        </div>
      </div>
      <div className="row">
        {filtered.length < 50 && filtered.length > 0 && (
          <ClientsTrendsGraph
            instances={instances}
            instanceDataKeys={instanceDataKeys}
            instanceData={instanceData}
            instanceId={filtered.map(c => c.orderInstanceId).join(',')}
          />
        )}
      </div>
      <div className="row">
        <div className="col-xs-12 col-lg-12">
          <ClientsTable
            data={data}
            clients={clients}
            industries={industries}
            instanceDataKeys={instanceDataKeys}
            products={products}
            updateFilter={updateFilter}
            defaultTariffFilter={defaultTariffFilter}
            filter={filter}
            instances={instances}
          />
        </div>
      </div>
    </>
  )
}

function fetchClients() {
  return httpGet('/clients.json')
}

function fetchIndustries() {
  return httpGet('/industries.json')
}

function fetchInstanceDataKeys() {
  return httpGet('/instance_data/keys.json')
}

function fetchInstanceData() {
  return httpGet('/instance_data.json')
}

function fetchProducts() {
  return httpGet('/admin/products.json')
}

function fetchInstances() {
  return httpGet('/instances.json')
}

function fetchData() {
  const clients = fetchClients()
  const industries = fetchIndustries()
  const instanceDataKeys = fetchInstanceDataKeys()
  const instanceData = fetchInstanceData()
  const products = fetchProducts()
  const instances = fetchInstances()

  return {
    clients: wrapPromise(clients),
    industries: wrapPromise(industries),
    instanceDataKeys: wrapPromise(instanceDataKeys),
    instanceData: wrapPromise(instanceData),
    products: wrapPromise(products),
    instances: wrapPromise(instances),
  }
}

const memoizedFilterData = memoizeOne(filterData)

function filterData(data, filter) {
  each(filter, f => {
    data = _filter(data, f)
  })

  return data
}

const memoizedMergeData = memoizeOne(mergeData)

function mergeData(clients, instances, instanceData, products) {
  let productsCollection = {}
  products.forEach(p => (productsCollection[p.id] = p))
  const yearInMiliseconds = 31536000000

  return map(clients, c => {
    const instanceId = c.orders.length > 0 ? c.orders[0]['instance_id'] : null
    const stats = instanceId ? instanceData[instanceId] : {}
    const activeOrder = c.orders.find(o => o.status == 'running')
    const order = activeOrder
      ? {
          orderInstanceId: instanceId,
          orderBillingPeriod: activeOrder.billing_period,
          orderStatus: activeOrder.status,
          orderProductId: activeOrder.product_id,
          orderTariff: productsCollection[activeOrder.product_id].name,
          orderCreatedAt: activeOrder.created_at,
        }
      : {}
    const instance = instances.find(i => i.id == instanceId)

    // helper for duration and LTV
    let orderDuration = null
    let ltv = null
    if (instance && activeOrder) {
      orderDuration = instance.subscription_canceled_at
        ? Math.round((new Date(instance.subscription_canceled_at) - new Date(activeOrder.created_at)) / yearInMiliseconds * 12)
        : Math.round((new Date() - new Date(activeOrder.created_at)) / yearInMiliseconds * 12)

      ltv = orderDuration * productsCollection[activeOrder.product_id].base_price
    }

    return {
      ...stats,
      ...order,
      blockedAt: instance?.blocked_at,
      isBlocked: instance?.blocked_at ? 1 : 0,
      subscriptionCanceledAt: instance?.subscription_canceled_at,
      hasActiveSubscription: instance?.subscription_canceled_at ? 0 : 1,
      hasFakturoidId: c.fakturoid_id ? 1 : 0,
      orderDuration,
      ltv,
      ...c,
    }
  })
}
