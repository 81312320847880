import { countBy, keys, map } from 'lodash'
import PieChart from './PieChart'

import { COMPANY_SIZE } from '@lib/enumerations'

const ClientsBySizeChart = ({ clients }) => {
  const counts = countBy(clients, 'company_size')
  const sizes = keys(COMPANY_SIZE)

  const data = map(sizes, size => ({ label: COMPANY_SIZE[size], value: counts[size] }))

  return <PieChart data={data} />
}

export default ClientsBySizeChart
