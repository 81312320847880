import { useState } from 'react'
import styled from 'styled-components'

import { formatDate } from '@lib/utils'
import InstanceDataGraph from './InstanceDataGraph'

const ClientDetailPanel = ({ data, instanceDataKeys, instances }) => {
  const [active, setActive] = useState('Journal Details 7')

  const baseKeys = ['orderTariff', 'orderCreatedAt', 'orderBillingPeriod', 'orderInstanceId']
  const localize = {
    orderTariff: 'Tariff',
    orderCreatedAt: 'Objednáno',
    orderBillingPeriod: 'Fakturační období',
    orderInstanceId: 'Instance ID',
  }

  const renderLabel = key => {
    return localize[key]
  }

  const renderValue = key => {
    let value = data[key]

    if (key == 'orderCreatedAt') {
      value = formatDate(value)
    }

    return value
  }

  const showGraph = key => {
    setActive(key)
  }

  return (
    <Container>
      <Title>{data.company}</Title>
      <Graph>
        {data.orderInstanceId && (
          <InstanceDataGraph
            key={active}
            instanceId={data.orderInstanceId}
            name={active}
            instances={instances}
          />
        )}
      </Graph>
      <Base>
        {baseKeys.map(key => (
          <Group key={key}>
            <Label>{renderLabel(key)}:</Label>
            <Value>{renderValue(key)}</Value>
          </Group>
        ))}
      </Base>
      <Stats>
        {instanceDataKeys.keys.map(key => (
          <GroupStats key={key} onClick={showGraph.bind(null, key)}>
            <LabelStats>{key}:</LabelStats>
            <ValueStats>{renderValue(key)}</ValueStats>
          </GroupStats>
        ))}
      </Stats>
    </Container>
  )
}

export default ClientDetailPanel

const Container = styled.div`
  font-size: 1.3rem;
  padding: 1.5rem;
  background-color: white;
  position: relative;
  z-index: 15;
`

const Title = styled.h5`
  margin-top: 0;
`

const Base = styled.div`
  margin-top: 1.5rem;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: center;
`

const Stats = styled.div`
  margin-top: 1.5rem;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: center;
`

const Graph = styled.div`
  margin-top: 1.5rem;
`

const Group = styled.div`
  display: inline-flex;
  flex-direction: row;
  cursor: pointer;
`

const Label = styled.div`
  display: flex;
  margin-right: 0.5rem;
`

const Value = styled.div`
  display: flex;
  font-weight: bold;
  margin-right: 1rem;
`

const GroupStats = styled.div`
  display: inline-flex;
  flex-direction: row;
  cursor: pointer;

  &:hover {
    text-decoration: underline;
  }
`

const LabelStats = styled.div`
  display: flex;
  width: 17rem;
  margin-right: 0.5rem;
`

const ValueStats = styled.div`
  display: flex;
  min-width: 9rem;
  font-weight: bold;
`
