import { ResponsiveContainer, PieChart as RePieChart, Pie, Cell, Tooltip } from 'recharts'

import { COLORS } from '@lib/enumerations'

const PieChart = ({ data }) => {
  const pieData = data

  const RADIAN = Math.PI / 180
  const renderCustomizedLabel = props => {
    const { cx, cy, midAngle, innerRadius, outerRadius, percent, index, label, value, fill } = props

    const radius = innerRadius + (outerRadius - innerRadius) * 1.4
    const x = cx + radius * Math.cos(-midAngle * RADIAN)
    const y = cy + radius * Math.sin(-midAngle * RADIAN)

    return (
      <text
        fill={fill}
        x={x}
        y={y}
        textAnchor={x > cx ? 'start' : 'end'}
        dominantBaseline="central"
      >
        {label}: {value}
      </text>
    )
  }

  return (
    <ResponsiveContainer width="100%" height={400}>
      <RePieChart>
        <Pie
          data={pieData}
          dataKey="value"
          nameKey="label"
          cx="50%"
          cy="50%"
          outerRadius={100}
          labelLine={true}
          label={renderCustomizedLabel}
        >
          {data.map((entry, index) => (
            <Cell key={`cell-${index}`} fill={COLORS[index % COLORS.length]} />
          ))}
        </Pie>
        <Tooltip />
      </RePieChart>
    </ResponsiveContainer>
  )
}

export default PieChart
