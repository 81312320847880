import { useState, Suspense } from 'react'
import styled from 'styled-components'
import each from 'lodash/each'
import reduce from 'lodash/reduce'

import memoizeOne from 'memoize-one'

import {
  LineChart,
  Line,
  Legend,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  ResponsiveContainer,
} from 'recharts'

import { httpGet, wrapPromise, formatDate } from '@lib/utils'
import { COLORS } from '@lib/enumerations'

const memoizedFetch = memoizeOne(fetchData)

const InstanceDataGraph = props => {
  const resource = memoizedFetch(props.instanceId, props.name)
  const instances = props.instances || []

  let instances_collection = {}
  instances.forEach(instance => {
    instances_collection[instance.id] = instance
  })

  return (
    <>
      <Suspense fallback={<i className="fa fa-spinner fa-spin" />}>
        <Graph {...props} data={resource.data} instances_collection={instances_collection} />
      </Suspense>
    </>
  )
}

export default InstanceDataGraph

const Graph = (props) => {
  const graphHeight = props.height || 200
  const source = props.data.read()
  const { instances_collection } = props
  const [tooltipInstanceId, setTooltipInstanceId] = useState(0)

  const getColor = id => COLORS[id % COLORS.length]

  if (source.message == 'Not found') {
    return <div>{props.name} - nejsou data</div>
  }

  const renderTooltip = ({ active, payload, label }) => {
    const value = payload && payload[0]?.payload[tooltipInstanceId]

    if (active && value) {
      const filtered = reduce(
        payload[0].payload,
        (acc, v, id) => {
          if (v == value && id != tooltipInstanceId) {
            acc.push(parseInt(id))
          }

          return acc
        },
        []
      )
      return (
        <StyledTooltip>
          <strong>{formatDate(label)}</strong>
          <div>{`${instances_collection[tooltipInstanceId]?.name} : ${value}`}</div>
          <Others>{filtered.map(id => instances_collection[id]?.name).join(', ')}</Others>
        </StyledTooltip>
      )
    }

    return null
  }

  const renderLegend = (value, entry) => {
    const { color } = entry

    return <span style={{ color }}>{instances_collection[value]?.name}</span>
  }

  let data = []
  each(source, (values, date) => {
    values['date'] = date
    data.push(values)
  })

  let lines = []
  const ids = (`${props.instanceId}`).split(',')

  if (props.recalculateForAARRR) {
    data = recalculateDataForAARRR(data, props.name)
  }

  ids.forEach((id, index) => {
    lines.push(
      <Line
        key={id}
        type="monotone"
        dataKey={id}
        stroke={getColor(id)}
        strokeWidth={2}
        activeDot={{
          onMouseOver: () => setTooltipInstanceId(id),
          onMouseOut: () => setTooltipInstanceId(0),
        }}
      />
    )
  })

  return (
    <div>
      <Title>{props.name}</Title>
      <div style={{ height: `${graphHeight}px` }}>
        <ResponsiveContainer>
          <LineChart
            width={500}
            height={300}
            data={data}
            margin={{
              top: 5,
              right: 30,
              left: 20,
              bottom: 5,
            }}
          >
            <CartesianGrid strokeDasharray="3 3" />
            <XAxis dataKey="date" tickFormatter={d => formatDate(d)} />
            <YAxis domain={[0, 'dataMax + 10']} />
            <Tooltip content={renderTooltip} />
            <Legend
              formatter={renderLegend}
              layout="vertical"
              align="right"
              verticalAlign="top"
              wrapperStyle={{ right: '0px' }}
            />
            {lines}
          </LineChart>
        </ResponsiveContainer>
      </div>
    </div>
  )
}

function fetchStats(instanceId, key) {
  const instance = instanceId ? `instance_id=${instanceId}` : ''
  return httpGet(`/instance_data/history.json?key=${key}&${instance}`)
}

function fetchData(instanceId, key) {
  const stats = fetchStats(instanceId, key)

  return {
    data: wrapPromise(stats),
  }
}


// recalculate 7 days cumulative data into every day diffs
// this function is simplified, has compromises
// in future we should scrape data every day
// it will work sufficiently for basic screnario: one main user (admin) is trying or not?
function recalculateDataForAARRR(data, keyName) {
  const dataByDays = []

  each(data, (values, i) => {
    let oneDay = { date: values.date }

    each(values, (value, instance_id) => {
      if (instance_id == 'date') return

      if (keyName == 'Last Seen 7') {
        const next1 = data[i + 1] && data[i+1][instance_id] !== undefined ? data[i + 1][instance_id] : null
        const next2 = data[i + 2] && data[i+2][instance_id] !== undefined ? data[i + 2][instance_id] : null
        const next3 = data[i + 3] && data[i+3][instance_id] !== undefined ? data[i + 3][instance_id] : null
        const next4 = data[i + 4] && data[i+4][instance_id] !== undefined ? data[i + 4][instance_id] : null
        const next5 = data[i + 5] && data[i+5][instance_id] !== undefined ? data[i + 5][instance_id] : null
        const next6 = data[i + 6] && data[i+6][instance_id] !== undefined ? data[i + 6][instance_id] : null
        const avg = (value + next1 + next2 + next3 + next4 + next5 + next6) / 7

        if (next6 !== null) { // first week, compromises with avg
          oneDay[instance_id] = next6 > 0 && avg >= 1 ? value : 0
        } else { // end of data graph, compromises
          oneDay[instance_id] = (next5 > 0 && next4 > 0 && next3 > 0 && next2 > 0 && next1 > 0)
              || (next5 === null && next4 > 0 && next3 > 0 && next2 > 0 && next1 > 0)
              || (next5 === null && next4 === null && next3 > 0 && next2 > 0 && next1 > 0)
              || (next5 === null && next4 === null && next3 === null && next2 > 0 && next1 > 0)
              || (next5 === null && next4 === null && next3 === null && next2 === null && next1 > 0)
              || (next5 === null && next4 === null && next3 === null && next2 === null && next1 === null)
            ? value : 0
        }
      } else { // inceremental data, all stats
        const previous = data[i - 1] && data[i-1][instance_id] ? data[i - 1][instance_id] : 0
        const prev7 = data[i - 7] && data[i-7][instance_id] ? data[i - 7][instance_id] : 0
        const prev8 = data[i - 8] && data[i-8][instance_id] ? data[i - 8][instance_id] : 0

        if (prev7 == 0 || prev8 == 0) { // first week, compromise, only incements
          const diff = value - previous
          oneDay[instance_id] = diff > 0 ? diff : 0
        } else { // after first week, use prev 7 and 8 for baseline change
          const diff = value - previous + prev7 - prev8
          oneDay[instance_id] = diff > 0 ? diff : 0
        }
      }
    })

    dataByDays.push(oneDay)
  })

  return dataByDays
}

const Title = styled.div`
  font-weight: bold;
  text-align: center;
`

const StyledTooltip = styled.div`
  background-color: white;
  border: 1px #ccc solid;
  padding: 1rem;
`

const Others = styled.div`
  border-top: 1px #ccc solid;
  padding-top: 1rem;
  margin-top: 1rem;
  font-size: 0.9em;
`
