import { countBy, map } from 'lodash'
import PieChart from './PieChart'

const ClientsByIndustryChart = ({ clients, industries }) => {
  const counts = countBy(clients, 'industry_id')

  const data = map(industries, i => ({ label: i.name, value: counts[i.id] }))

  return <PieChart data={data} />
}

export default ClientsByIndustryChart
