// Entry point for the build script in your package.json
// import '@hotwired/turbo-rails'
import Rails from '@rails/ujs'

// import * as ActiveStorage from '@rails/activestorage'

import 'lodash' // inject lodash as _ to global context, simply its handy
import 'chartkick/chart.js'

import React from 'react'
import { createRoot } from 'react-dom/client';

window.React = React

import ErrorBoundary from '@components/ErrorBoundary'
import ClientsReport from '@components/reports/ClientsReport'
import TrendsReport from '@components/reports/TrendsReport'
import InstancesReport from '@components/reports/InstancesReport'
import CallsReport from '@components/reports/Calls'
import RetentionReport from '@components/reports/Retention'
import AarrrReport from '@components/reports/Aarrr'

const MOUNTABLE_COMPONENTS = { ClientsReport, TrendsReport, InstancesReport, CallsReport, RetentionReport, AarrrReport }

function mountComponents() {
  const targets = document.querySelectorAll('div[data-component]')

  targets.forEach(target => {
    const Component = MOUNTABLE_COMPONENTS[target.dataset.component]

    if (Component) {
      const { props } = target.dataset
      const propsObj = props ? JSON.parse(props) : {}
      const markup = (
        <ErrorBoundary>
          <Component {...propsObj} />
        </ErrorBoundary>
      )

      const root = createRoot(target)
      root.render(markup)
    }
  })
}

// ActiveStorage.start()
Rails.start()

document.addEventListener('DOMContentLoaded', () => {
  mountComponents()
})
