import { useState, Suspense } from 'react'
import { filter, minBy, maxBy, sortBy, uniqBy } from 'lodash'
import memoizeOne from 'memoize-one'
import Select from 'react-select'

import { StyledTooltip, Row, Label, Value, ValueBig, QuickBtn } from './atoms'

import {
  LineChart,
  Line,
  Legend,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  ResponsiveContainer,
} from 'recharts'

import { formatDate, formatDateForInput } from '@lib/utils'
import { COLORS } from '@lib/enumerations'

const TRIAL_PRODUCT_ID = 3
const PROMO_PRODUCT_ID = 10
const RED = COLORS[10]

const OrdersGraph = props => {
  const graphHeight = props.height || 400
  const { instances, clients, orders, products } = props

  const [tooltipInstanceId, setTooltipInstanceId] = useState(0)
  const [showUsers, setShowUsers] = useState({ label: 'ne', value: 0 })
  const [from, setFrom] = useState(null)
  const [to, setTo] = useState(null)
  const [showChurn, setShowChurn] = useState({ label: 'ne', value: 0 })

  const instancesLookup = memoizeOne(i => { return i.reduce((acc, instance) => { acc[instance.id] = instance; return acc }, {}) })(instances)
  const clientsLookup = memoizeOne(c => { return c.reduce((acc, client) => { acc[client.id] = client; return acc }, {}) })(props.clients)
  const productsLookup = memoizeOne(p => { return p.reduce((acc, product) => { acc[product.id] = product; return acc }, {}) })(products)
  const usersLookup = { 1: 5, 2: 12, 3: 0, 4: 2, 5: 20, 6: 30, 7: 40, 8: 50, 9: 5, 10: 0, 11: 200 }

  // Create source for data graph from orders and cancellations
  let events = []

  const paidTariffs = orders.filter(order => order.product_id != TRIAL_PRODUCT_ID && order.product_id != PROMO_PRODUCT_ID)
  paidTariffs.forEach(order => {
    events.push({ date: new Date(order.created_at), company: 1, users: usersLookup[order.product_id], instanceId: order.instance_id, clientId: order.client?.id })
  })

  const canceledSubscriptions = instances.filter(i => i.subscription_canceled_at)
  canceledSubscriptions.forEach(instance  => {
    //const client = clientsLookup[instance.client_id]
    //const order = client && client.orders && client.orders[0] || { product_id: 1 }
    let order = filter(orders, { instance_id: instance.id })[0]

    if (!order) {
      order = { product_id: 1 }
    }

    events.push({ date: new Date(instance.subscription_canceled_at), company: -1, users: -(usersLookup[order.product_id]), instanceId: instance.id, clientId: instance.client_id })
  })

  // Filter and sort
  if (from && to) {
    events = events.filter(e => e.date >= new Date(from) && e.date <= new Date(to))
  } else if (from) {
    events = events.filter(e => e.date >= new Date(from))
  } else if (to) {
    events = events.filter(e => e.date <= new Date(to))
  }

  events = sortBy(events, ['date'])

  let data = []
  let companiesSum = 0
  let usersSum = 0
  let netSum = 0
  let netUsersSum = 0
  let churnSum = 0
  let usersChurnSum = 0

  events.forEach(e => {
    netSum = netSum + e.company
    netUsersSum = netUsersSum + e.users

    companiesSum = e.company > 0 ? companiesSum + e.company : companiesSum
    usersSum = e.users > 0 ? usersSum + e.users : usersSum

    churnSum = e.company < 0 ? churnSum + e.company : churnSum
    usersChurnSum = e.users < 0 ? usersChurnSum + e.users : usersChurnSum

    if (e.company < 0 && showChurn.value == 1) {
      data.push({ date: e.date.valueOf(), usersChurnSum, churnSum, netSum, netUsersSum, instanceId: e.instanceId, clientId: e.clientId })
    } else {
      data.push({ date: e.date.valueOf(), usersSum,  companiesSum, netSum, netUsersSum, instanceId: e.instanceId, clientId: e.clientId })
    }
  })

  data = uniqBy(data, 'date')

  const min = minBy(data, 'date') || { date: new Date() }
  const max = maxBy(data, 'date') || { date: new Date() }

  const minYear = new Date(min.date).getFullYear()
  const minYearDate = new Date(minYear, 0, 1).valueOf()
  const yearInMiliseconds = 31536000000
  const years = Math.ceil((max.date - min.date) / yearInMiliseconds)

  const ticks = []
  for (let i = 0; i <= years; i++) {
    ticks.push(minYearDate + (i * yearInMiliseconds))
  }

  const renderTooltip = ({ active, payload, label }) => {
    const event = payload && payload[0]?.payload

    if (active && event) {
      const instance = instancesLookup[event.instanceId]
      const client = clientsLookup[event.clientId]
      const order = client && client.orders && client.orders[0]
      const tariff = productsLookup[order?.product_id]

      let duration = null
      if (instance.subscription_canceled_at && order) {
        duration = Math.round(((new Date(instance.subscription_canceled_at) - new Date(order.created_at)) / yearInMiliseconds) * 10) / 10
      }


      return (
        <StyledTooltip>
          <strong>{formatDate(label)}</strong>
          <div style={{ color: (duration ? RED : '#000000') }}>{instance?.name}</div>
          <div style={{ fontSize: '0.9em'}}>
            <div>Tarif: {tariff?.name}</div>
            {showUsers.value == 1 && <div>Uživatelů: {event.netUsersSum}</div>}
            {duration && <>
              <div>Objednáno: {formatDate(order.created_at)}</div>
              <div>Ukončeno: {formatDate(instance.subscription_canceled_at)}</div>
              <div>Trvání: {duration} let</div>
            </>}
          </div>
        </StyledTooltip>
      )
    }

    return null
  }

  const yesNoOptions = [
    { label: 'ne', value: 0 },
    { label: 'ano', value: 1 },
  ]

  const setYear = year => {
    setFrom(new Date(year, 0, 1).valueOf())
    setTo(new Date(year, 11, 31).valueOf())
  }

  const setLastMonths = months => {
    const dateTo = new Date()
    const monthAsMiliseconds = 2628000000
    const dateFrom = new Date(dateTo - (months * monthAsMiliseconds))

    setFrom(dateFrom.valueOf())
    setTo(dateTo.valueOf())
  }

  const renderLastTen = type => {
    const filterdData = type == 'netSum' ? events.filter(e => e.company > 0) : events.filter(e => e.company < 0)
    const reversed = filterdData.reverse()
    const slice = reversed.slice(0, 10)

    return slice.map(e => {
      let client = clientsLookup[e.clientId]
      let orders = client?.orders || []

      // for deleted instances, they cause that client has no orders
      if (client && orders == 0) {
        const instances = props.instances.filter(i => i.client_id == client.id)
        const i_ids = instances.map(i => i.id)
        orders = props.orders.filter(o => i_ids.includes(o.instance_id))

        client.orders = orders
      }

      const tariff = productsLookup[client?.orders[0]?.product_id]

      return (
        <li key={client?.id}>
          <a href={`https://info.projektove.cz/issues/${client?.crm_client_card_id}`} target="_blank" style={{ color: '#666' }}>
            {client?.company} ({tariff?.name})
          </a>
        </li>
      )
    })
  }

  return (
    <div>
      <div style={{ marginBottom: '3rem'}}>
        <Row>
          <Label>Zobrazit uživatele:</Label>
          <Value>
              <Select options={yesNoOptions} value={showUsers} onChange={o => setShowUsers(o)} />
          </Value>
          <Label>Zobrazit churn:</Label>
          <Value>
              <Select options={yesNoOptions} value={showChurn} onChange={o => setShowChurn(o)} />
          </Value>
          <Label>Od:</Label>
          <Value>
            <input
              type="date"
              value={from ? formatDateForInput(from) : ''}
              onChange={e => e.target.value ? setFrom(e.target.valueAsNumber) : setFrom(null) }
              max={to ? formatDateForInput(to) : ''}
            />
          </Value>
          <Label>Do:</Label>
          <Value>
            <input
              type="date"
              value={to ? formatDateForInput(to) : ''}
              onChange={e => e.target.value ? setTo(e.target.valueAsNumber) : setTo(null) }
              min={from ? formatDateForInput(from) : ''}
            />
          </Value>
        </Row>
        <Row>
          {[2014, 2015, 2016, 2017, 2018, 2019, 2020, 2021, 2022, 2023].map(year => (
            <QuickBtn key={year} onClick={() => setYear(year)}>{year}</QuickBtn>
          ))}
          {[3, 6, 12].map(months => (
            <QuickBtn key={months} onClick={() => setLastMonths(months)}>posl. {months} měsíců</QuickBtn>
          ))}

            <QuickBtn onClick={() => { setFrom(null);setTo(null)}}>vše</QuickBtn>
        </Row>
      </div>

      <div>
        <div className="row">
          <div className="col-md-10" style={{ height: `${graphHeight}px` }}>
            <ResponsiveContainer>
              <LineChart
                width={500}
                height={300}
                data={data}
                margin={{
                  top: 5,
                  right: 30,
                  left: 20,
                  bottom: 5,
                }}
              >
                <CartesianGrid strokeDasharray="3 3" />
                <XAxis scale="time" dataKey="date" tickFormatter={d => formatDate(d)} ticks={ticks} />
                <YAxis domain={[0, 'dataMax + 10']} />
                <Tooltip labelFormatter={d => formatDate(d)} content={renderTooltip} />
                <Legend />
                <Line
                  type="monotone"
                  dataKey="netSum"
                  stroke={COLORS[0]}
                  strokeWidth={2}
                />
                {showChurn.value == 1 && <>
                  <Line
                    type="monotone"
                    dataKey="companiesSum"
                    stroke={COLORS[4]}
                    strokeWidth={2}
                  />
                  <Line
                    type="monotone"
                    dataKey="churnSum"
                    stroke={RED}
                    strokeWidth={2}
                  />
                </>}
                {showUsers.value == 1 && (
                  <Line
                    type="monotone"
                    dataKey="netUsersSum"
                    stroke={COLORS[1]}
                    strokeWidth={2}
                  />
                )}
              </LineChart>
            </ResponsiveContainer>
          </div>
          <div className="col-md-2">
            <h2 style={{ marginTop: 0 }}>{netSum}</h2>
            {showChurn.value == 1 && <>
              <div>Zisk: <span style={{ fontWeight: 'bold' }}>{companiesSum}</span></div>
              <div>Ztráta: <span style={{ fontWeight: 'bold', color: RED }}>{churnSum}</span></div>
            </>}

            <div style={{ fontSize: '0.8em' }}>
              <div>
                <strong>Posledních 10 nových klientů</strong>
                <ul style={{ paddingLeft: '1.5rem' }}>
                  {renderLastTen('netSum')}
                </ul>
              </div>

              {showChurn.value == 1 && (
                <div>
                  <strong style={{ color: RED }}>Posledních 10 ztracených klientů</strong>
                  <ul style={{ paddingLeft: '1.5rem' }}>
                    {renderLastTen('churnSum')}
                  </ul>
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default OrdersGraph
