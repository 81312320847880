import { Suspense, useState, useEffect, useRef } from 'react'

import styled from 'styled-components'
import memoizeOne from 'memoize-one'
import dayjs from 'dayjs'
import 'dayjs/locale/cs'
dayjs.locale('cs')

import orderBy from 'lodash/orderBy'

import { httpGet, wrapPromise, formatDate } from '@lib/utils'
import { Loading } from '../../shared'

import { Title, NavPills, NavPill, Table, InstanceName, CRMBtn } from '../atoms'
import InstanceDataGraph from '../InstanceDataGraph'

const RetentionReport = props => {
  const resource = fetchData()

  return (
    <>
      <Suspense fallback={<Loading />}>
        <Retention
          retention={resource.retention}
          clients={resource.clients}
          instances={resource.instances}
          products={resource.products}
        />
      </Suspense>
    </>
  )
}

export default RetentionReport

const Retention = props => {
  const retention = props.retention.read()
  const clients = props.clients.read()
  const products = props.products.read()
  const instancesSource = props.instances.read()

  const [orderInstancesBy, setOrderInstancesBy] = useState('name')
  const [orderInstancesByDirection, setOrderInstancesByDirection] = useState('asc')

  const [reportType, setReportType] = useState('paid')

  // Myšlenkový směr
  // aktivita a tyden 0, prvni zachyt
  // aktivita a 2 tydny po sobe 0
  // opacne - 0 a pak aktivita
  // API - porovnat s poslednim nebo 2 max cisly, jestli uz nejsou OK

  // pro ARRRR metriky bude podobny report, ale s trialy
  const [detail, setDetail] = useState(null)

  const instances = memoizedMergeData(clients, instancesSource, products, retention)

  const badCondition = i => {
    return i.retention.has_zero_journals['3m'] || i.retention.has_zero_journals['2m'] || i.retention.has_zero_journals['1m']
  }

  const reportTypeHash = {
    paid: i => !i.client?.activeOrder?.product?.is_free && badCondition(i),
    trial: i => i.client?.activeOrder?.product?.name == 'Trial',
    promo: i => i.client?.activeOrder?.product?.name == 'Promo' && badCondition(i),
    firstCatch: i => !i.retention.has_zero_journals['3m']
      && !i.retention.has_zero_journals['2m']
      && i.retention.has_zero_journals['7d']
      && i.client?.activeOrder?.product?.name != 'Trial',
    badTwoWeeks: i => !i.retention.has_zero_journals['3m']
      && !i.retention.has_zero_journals['2m']
      && i.retention.has_zero_journals['14d']
      && i.retention.has_zero_journals['7d']
      && i.client?.activeOrder?.product?.name != 'Trial',
    reactivated: i => i.retention.has_zero_journals['3m']
      && i.retention.has_zero_journals['2m']
      && !i.retention.has_zero_journals['14d']
      && !i.retention.has_zero_journals['7d']
      && i.client?.activeOrder?.product?.name != 'Trial',
  }

  const instancesToReport = instances.filter(reportTypeHash[reportType])

  const orderHash = {
    name: 'name',
    tarif: 'client.activeOrder.product.name',
    createdAt: 'created_at',
    'j3m': 'retention.has_zero_journals["3m"]',
    'j2m': 'retention.has_zero_journals["2m"]',
    'j1m': 'retention.has_zero_journals["1m"]',
    'j1d': 'retention.has_zero_journals["14d"]',
    'j7d': 'retention.has_zero_journals["7d"]',
  }

  const ordered = orderInstancesBy == 'tarif'
    ? orderBy(instancesToReport, i => parseInt(i.client?.activeOrder.product.base_price), [orderInstancesByDirection == 'asc' ? 'desc' : 'asc'])
    : (
      orderInstancesBy == 'createdAt'
        ? orderBy(instancesToReport, i => Date.parse(i), [orderInstancesByDirection])
        : orderBy(instancesToReport, orderHash[orderInstancesBy], [orderInstancesByDirection])
    )

  const changeOrder = name => {
    if (orderInstancesBy == name) {
      setOrderInstancesByDirection(orderInstancesByDirection == 'asc' ? 'desc' : 'asc')
    } else {
      setOrderInstancesBy(name)
    }
  }

  const changeReport = type => {
    setReportType(type)
    setDetail(null)
  }

  return (
    <Report>
      <NavPills>
        <NavPill active={reportType == 'paid' && 'active'} onClick={() => changeReport('paid')}>Klienti</NavPill>
        <NavPill active={reportType == 'trial' && 'active'} onClick={() => changeReport('trial')}>Zkušební verze</NavPill>
        <NavPill active={reportType == 'promo' && 'active'} onClick={() => changeReport('promo')}>Promo</NavPill>
        <NavPill active={reportType == 'firstCatch' && 'active'} onClick={() => changeReport('firstCatch')}>První záchyt</NavPill>
        <NavPill active={reportType == 'badTwoWeeks' && 'active'} onClick={() => changeReport('badTwoWeeks')}>2 týdny 0</NavPill>
        <NavPill active={reportType == 'reactivated' && 'active'} onClick={() => changeReport('reactivated')}>Reaktivované</NavPill>
      </NavPills>
      <div className="row">
        <div className="col-xs-12 col-sm-6">
          <Table>
            <thead>
              <tr>
                <th colSpan="3"></th>
                <th colSpan="3">poslední</th>
                <th colSpan="2">posledních</th>
              </tr>
              <tr>
                <th onClick={() => changeOrder('name')} style={{ textAlign: 'left' }}>Instance</th>
                <th></th>
                <th onClick={() => changeOrder('tarif')} style={{ textAlign: 'left', minWidth: '9rem' }}>Tarif</th>
                <th onClick={() => changeOrder('createdAt')}>Vytvořeno</th>
                <th onClick={() => changeOrder('j3m')}>3 měsíce</th>
                <th onClick={() => changeOrder('j2m')}>2 měsíce</th>
                <th onClick={() => changeOrder('j1m')}>měsíc</th>
                <th onClick={() => changeOrder('j14d')}>14 dní</th>
                <th onClick={() => changeOrder('j7d')}>7 dní</th>
              </tr>
            </thead>
            <tbody>
              {ordered.map(instance => (
                <tr key={instance.id}>
                  <td>
                    <InstanceName onClick={setDetail.bind(this, instance)}>{instance.name}</InstanceName>
                  </td>
                  <td>
                    <CRMBtn cardId={instance.client?.crm_client_card_id} />
                  </td>
                  <td>{instance.client?.activeOrder?.product?.name}</td>
                  <td className="Table__date">{formatDate(instance.created_at, { short: reportType == 'trial' })}</td>
                  <td>{instance.retention.has_zero_journals['3m'] && (<Excl>!</Excl>)}</td>
                  <td>{instance.retention.has_zero_journals['2m'] && (<Excl>!</Excl>)}</td>
                  <td>{instance.retention.has_zero_journals['1m'] && (<Excl>!</Excl>)}</td>
                  <td>{instance.retention.has_zero_journals['14d'] && (<Excl>!</Excl>)}</td>
                  <td>{instance.retention.has_zero_journals['7d'] && (<Excl>!</Excl>)}</td>
                </tr>
              ))}
            </tbody>
          </Table>
        </div>
        <div className="col-xs-12 col-sm-6">
          {detail && (
            <div>
              <Title>{detail.name}</Title>
              <div>
                Objevila se 0 aktivita.
              </div>
              <InstanceDataGraph
                instanceId={detail.id}
                name={"Journal Details 7"}
                instances={instances}
                recalculateForAARRR={reportType == 'trial'}
              />
              <InstanceDataGraph
                instanceId={detail.id}
                name={"Last Seen 7"}
                instances={instances}
                recalculateForAARRR={reportType == 'trial'}
              />
              <InstanceDataGraph
                instanceId={detail.id}
                name={"Tasks Created 7"}
                instances={instances}
                recalculateForAARRR={reportType == 'trial'}
              />
              <InstanceDataGraph
                instanceId={detail.id}
                name={"Projects Created 7"}
                instances={instances}
                recalculateForAARRR={reportType == 'trial'}
              />
            </div>
          )}
        </div>
      </div>
    </Report>
  )
}

function fetchRetention() {
  return httpGet('/instance_data/retention.json')
}

function fetchClients() {
  return httpGet('/clients.json')
}

function fetchInstances() {
  return httpGet('/instances.json')
}

function fetchProducts() {
  return httpGet('/admin/products.json')
}

function fetchData() {
  const retention = fetchRetention()
  const clients = fetchClients()
  const products = fetchProducts()
  const instances = fetchInstances()

  return {
    retention: wrapPromise(retention),
    clients: wrapPromise(clients),
    products: wrapPromise(products),
    instances: wrapPromise(instances),
  }
}

const memoizedMergeData = memoizeOne(mergeData)

function mergeData(clients, instances, products, retentionData) {
  const productsCollection = products.reduce((acc, p) => { acc[p.id] = p; return acc }, {})
  const clientsCollection = clients.reduce((acc, c) => {
      const activeOrder = c.orders.find(o => o.status == 'running')

      if (activeOrder?.instance_id) {
        activeOrder.product = productsCollection[activeOrder.product_id]
        acc[activeOrder.instance_id] = {
          activeOrder,
          ...c,
        }
      }

      return acc;
    }, {})

  return instances.map(i => {
    const client = clientsCollection[i.id]

    return {
      ...i,
      client,
      retention: {
        has_zero_journals: {
          '3m': retentionData.has_zero_journals['3m'].includes(i.id),
          '2m': retentionData.has_zero_journals['2m'].includes(i.id),
          '1m': retentionData.has_zero_journals['1m'].includes(i.id),
          '14d': retentionData.has_zero_journals['14d'].includes(i.id),
          '7d': retentionData.has_zero_journals['7d'].includes(i.id),
        }
      }
    }
  })
}

export const Report = styled.div`
  margin-bottom: 10rem;
`

export const Excl = styled.div`
  font-weight: bold;
  color: red;
  text-align: center;
`
