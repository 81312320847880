import styled from 'styled-components'

export const StyledTooltip = styled.div`
  background-color: white;
  border: 1px #ccc solid;
  padding: 1rem;
`

export const Title = styled.div`
  font-size: 30px;
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 0.5rem;
`

export const Subtitle = styled.div`
  font-size: 24px;
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 0.5rem;
`

export const Row = styled.div`
  display: flex;
  flex-direction: row;
  cursor: pointer;
  justify-content: flex-start;
  align-items: center;
  margin-bottom: 1rem;
`

export const Label = styled.div`
  display: flex;
  min-width: 3rem;
  margin-right: 0.5rem;
  justify-content: flex-end;
  align-items: center;
`

export const Value = styled.div`
  display: flex;
  min-width: 9rem;
  margin-right: 1rem;
`

export const ValueBig = styled.div`
  display: block;
  width: 75%;
`

export const QuickBtn = styled.div`
  font-size: 0.9rem;
  margin-right: 1rem;
  &:hover {
    text-decoration: underline;
  }
`
export const NavPills = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  margin-bottom: 1rem;
`
export const NavPill = styled.div`
  padding: 0.5rem 1rem;
  padding: 1rem 1.5rem;
  border-radius: 3px;
  margin-right: 0.5rem;
  cursor: pointer;
  background-color: ${props => props.active ? '#2196f3' : 'white'};
  color: ${props => props.active ? 'white' : '#2196f3'};
  &:hover {
    background-color: ${props => props.active ? '#337ab7' : '#eee'};
    color: ${props => props.active ? 'white' : '#337ab7'};
  }
`

export const Table = styled.table`
  th, td {
    padding: 0 0.5rem;
  }

  th {
    white-space: nowrap;
    text-align: center;

    &:first-child {
      text-align: left;
    }

    &:hover {
      text-decoration: underline;
      cursor: pointer;
    }
  }

  .Table__delimiter div {
    background-color: #eee;
    font-size: 0.8em;
    margin: 0.5rem 0;
    border-radius: 1em;
    text-align: center;
    width: 110%;
    margin-left: -5%;
    margin-top: 1rem;
    margin-bottom: 0.5rem;
  }

  .Table__date {
    text-align: right;
    min-width: 9rem;
  }
`
export const InstanceName = styled.div`
  cursor: pointer;
  width: 18rem;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;

  &:hover {
    text-decoration: underline;
  }
`

export const ActionBtn = styled.div`
  a.btn {
    display: flex;
    flex-direction: row;
    align-items: center;
    font-size: 1rem;
    line-height: 1rem;
    padding: 0.3rem 0.5rem;
    gap: 0.5rem;
  }
`

export const FloatRight = styled.span`
  float: right;
`

export const CRMBtn = props => {
  if (!props.cardId) return null

  return (
    <ActionBtn>
      <a className="btn btn-default" target="_blank" href={`https://app.projektove.cz/tasks/${props.cardId}`}><i className="fa fa-building-o" />CRM</a>
    </ActionBtn>
  )
}
