import { useState, Suspense } from 'react'
import Select from 'react-select'
import styled from 'styled-components'
import map from 'lodash/map'
import orderBy from 'lodash/orderBy'
import filter from 'lodash/filter'
import take from 'lodash/take'
import takeRight from 'lodash/takeRight'

import { httpGet, wrapPromise } from '@lib/utils'

import { Loading } from '../shared'

import InstanceDataGraph from './InstanceDataGraph'

const TrendsReport = props => {
  const resource = fetchData()

  return (
    <>
      <Suspense fallback={<Loading />}>
        <Trends
          instances={resource.instances}
          instanceDataKeys={resource.instanceDataKeys}
          instanceData={resource.instanceData}
        />
      </Suspense>
    </>
  )
}

export default TrendsReport

const Trends = props => {
  const [key, setKey] = useState({ label: 'Last Seen 14', value: 'Last Seen 14' })
  const instanceDataKeys = props.instanceDataKeys.read()
  const dataKeysOptions = instanceDataKeys.keys.map(k => ({ label: k, value: k }))

  const [scope, setScope] = useState({
    label: 'top 20',
    value: 'top20',
    size: 20,
    sort: 'desc',
    type: 'top',
  })
  const instances = props.instances.read()
  const instancesOptions = [
    { label: 'top 20', value: 'top20', size: 20, sort: 'desc', type: 'top' },
    { label: 'top 30', value: 'top30', size: 30, sort: 'desc', type: 'top' },
    { label: 'top 50', value: 'top40', size: 50, sort: 'desc', type: 'top' },
    { label: 'middle 20', value: 'middle20', size: 20, sort: 'desc', type: 'middle' },
    { label: 'middle 30', value: 'middle30', size: 30, sort: 'desc', type: 'middle' },
    { label: 'middle 50', value: 'middle40', size: 50, sort: 'desc', type: 'middle' },
    { label: 'bottom 20', value: 'bottom20', size: 20, sort: 'asc', type: 'bottom' },
    { label: 'bottom 30', value: 'bottom30', size: 30, sort: 'asc', type: 'bottom' },
    { label: 'bottom 50', value: 'bottom40', size: 50, sort: 'asc', type: 'bottom' },
  ]

  const instanceData = props.instanceData.read()

  let instanceId = null

  if (scope && key) {
    const merged = map(instanceData, (data, id) => ({ instance_id: id, ...data }))
    const filtered = filter(merged, d => d[key.value] > 0)
    const ordered = orderBy(filtered, [key.value], [scope.sort])

    if (scope.type == 'top' || scope.type == 'bottom') {
      const taken = take(ordered, scope.size)

      const ids = taken.map(d => d.instance_id)
      instanceId = ids.join(',')
    } else if (scope.type == 'middle') {
      const middle = Math.round(instanceData.length / 2)
      const halfScope = Math.round(scope.size / 2)

      const taken = take(ordered, middle + halfScope)

      const ids = taken.map(d => d.instance_id)
      instanceId = ids.join(',')
    }
  }

  return (
    <>
      <Group>
        <Label>Klíč:</Label>
        <Value>
          <Select options={dataKeysOptions} value={key} onChange={item => setKey(item)} />
        </Value>
        <Label>Rozsah:</Label>
        <Value>
          <Select
            options={instancesOptions}
            value={scope}
            onChange={item => setScope(item)}
            isClearable
            placeholder="vybrat ..."
          />
        </Value>
      </Group>
      <Graph>
        {key.value && (
          <InstanceDataGraph
            name={key.value}
            height={700}
            instances={instances}
            instanceId={instanceId}
          />
        )}
      </Graph>
    </>
  )
}

function fetchInstances() {
  return httpGet('/instances.json?tariff=paid')
}

function fetchInstanceDataKeys() {
  return httpGet('/instance_data/keys.json')
}

function fetchInstanceData() {
  return httpGet('/instance_data.json?tariff=paid')
}

function fetchData() {
  const instances = fetchInstances()
  const instanceDataKeys = fetchInstanceDataKeys()
  const instanceData = fetchInstanceData()

  return {
    instances: wrapPromise(instances),
    instanceDataKeys: wrapPromise(instanceDataKeys),
    instanceData: wrapPromise(instanceData),
  }
}

const Group = styled.div`
  display: flex;
  flex-direction: row;
  cursor: pointer;
  justify-content: flex-start;
  align-items: center;
`

const Label = styled.div`
  display: flex;
  margin-right: 1rem;
  font-weight: bold;
`

const Value = styled.div`
  display: block;
  min-width: 30rem;
  margin-right: 1.5rem;
`

const Graph = styled.div`
  margin-top: 2rem;
`
