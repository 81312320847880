import { useState, useEffect } from 'react'
import styled from 'styled-components'

import {
  BarChart,
  Bar,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
  LabelList,
  ResponsiveContainer,
} from 'recharts'

import find from 'lodash/find'
import meanBy from 'lodash/meanBy'
import sumBy from 'lodash/sumBy'

import Select from 'react-select'

import { COLORS } from '@lib/enumerations'

const days = 14
const defaultKeys = [
  `Last Seen ${days}`,
  `Journal Details ${days}`,
  `Journal Details by Admin ${days}`,
  `Projects Created ${days}`,
  `Tasks Created ${days}`,
  `TE Created ${days}`,
  `BE Created ${days}`,
  `Attachments Created ${days}`,
]

const ClientsBaseStatsGraph = ({ clients, instanceDataKeys }) => {
  const [type, setType] = useState({ label: 'průměr', value: 'mean' })
  const [keys, setKeys] = useState([])

  useEffect(() => {
    if (keys.length == 0) {
      setDefault()
    }
  })

  const setDefault = () => {
    const options = defaultKeys.map(k => ({ label: k, value: k }))

    setKeys(options)
  }

  const onChangeKeys = items => {
    if (items.length == 0) {
      setDefault()
    } else {
      setKeys(items)
    }
  }

  const dataKeysOptions = instanceDataKeys.keys.map(k => ({ label: k, value: k }))
  const typeOptions = [
    { label: 'průměr', value: 'mean' },
    { label: 'součet', value: 'sum' },
  ]

  const group = {}
  const operation = type.value == 'mean' ? meanBy : sumBy
  keys.forEach(k => (group[k.value] = Math.round(operation(clients, k.value))))
  const data = [group]

  return (
    <div>
      <ResponsiveContainer width="100%" height={400}>
        <BarChart width={730} height={250} data={data}>
          <CartesianGrid strokeDasharray="3 3" />
          <YAxis domain={[0, 'dataMax + 10']} />
          <Tooltip />
          <Legend verticalAlign="top" />
          {keys.map((key, i) => (
            <Bar key={key.value} dataKey={key.value} fill={COLORS[i]}>
              <LabelList dataKey={key.value} position="top" />
            </Bar>
          ))}
        </BarChart>
      </ResponsiveContainer>
      <div>
        <Group>
          <Value>
            <Select options={typeOptions} value={type} onChange={i => setType(i)} />
          </Value>
          <ValueBig>
            <Select options={dataKeysOptions} isMulti value={keys} onChange={onChangeKeys} />
          </ValueBig>
        </Group>
      </div>
    </div>
  )
}

export default ClientsBaseStatsGraph

const Group = styled.div`
  display: flex;
  flex-direction: row;
  cursor: pointer;
  justify-content: center;
  align-items: center;
`

const Value = styled.div`
  display: flex;
  min-width: 9rem;
  margin-right: 0.5rem;
`

const ValueBig = styled.div`
  display: block;
  width: 75%;
`
