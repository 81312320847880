import { countBy, uniq, map } from 'lodash'
import PieChart from './PieChart'

const ClientsByTariffChart = ({ clients }) => {
  const counts = countBy(clients, 'orderTariff')
  const tariffs_ar = map(clients, c => c.orderTariff)
  const tariffs = uniq(tariffs_ar)

  const data = map(tariffs, t => ({ label: t, value: counts[t] }))

  return <PieChart data={data} />
}

export default ClientsByTariffChart
