import { Component } from 'react'

export default class ErrorBoundary extends Component {
  state = { hasError: false }

  static getDerivedStateFromError(error) {
    return { hasError: true }
  }

  componentDidCatch(error, errorInfo) {
    // logErrorToMyService(error, errorInfo)
    console.log('error:', error)
    console.log('errorInfo:', errorInfo)
  }

  render() {
    return this.state.hasError ? <h4>Something went wrong.</h4> : this.props.children
  }
}
