/** @format */

//export const COLORS = ['#0088FE', '#00C49F', '#FFBB28', '#FF8042']
export const COLORS = [
  '#0088FE',
  '#00C49F',
  '#FFBB28',
  '#FF8042',
  '#FFB900',
  '#E74856',
  '#0078D7',
  '#0099BC',
  '#7A7574',
  '#FF8C00',
  '#E81123',
  '#2D7D9A',
  '#5D5A58',
  '#F7630C',
  '#EA005E',
  '#8E8CD8',
  '#00B7C3',
  '#68768A',
  '#CA5010',
  '#C30052',
  '#6B69D6',
  '#038387',
  '#515C6B',
  '#DA3B01',
  '#E3008C',
  '#8764B8',
  '#00B294',
  '#567C73',
  '#EF6950',
  '#BF0077',
  '#744DA9',
  '#018574',
  '#486860',
  '#D13438',
  '#C239B3',
  '#B146C2',
  '#847545',
  '#498205',
  '#FF4343',
  '#9A0089',
  '#881798',
  '#10893E',
  '#107C10',
]

export const COMPANY_SIZE = {
  unspecified: 'neuvedeno',
  small: 'malá',
  medium: 'střední',
  large: 'velká',
}

export const PRO_USERS = {
  0: {
    id: 0,
    name: 'Nepřiřazeno',
    position: 5,
  },
  3: {
    id: 3,
    name: 'Zdeněk',
    position: 2,
  },
  4: {
    id: 4,
    name: 'Richard',
    position: 4,
  },
  55: {
    id: 55,
    name: 'IS',
    position: 3,
  },
  125: {
    id: 125,
    name: 'Silvia',
    position: 1,
  },
}
