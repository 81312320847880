import { memo } from 'react'
import { groupBy } from 'lodash'
import MaterialTable, { MTableBody } from '@material-table/core'

import { COMPANY_SIZE } from '@lib/enumerations'

import { getColumns } from './enumerations'
import ClientDetailPanel from './ClientDetailPanel'

const ClientsTable = ({
  data,
  industries,
  instanceDataKeys,
  filter,
  updateFilter,
  products,
  defaultTariffFilter,
  instances,
}) => {
  const columns = getColumns({
    industries,
    companySizes: COMPANY_SIZE,
    instanceDataKeys,
    products,
    defaultTariffFilter,
  })

  const onFilterChanged = (bodyProps, columnId, value) => {
    const column = columns[columnId]

    if (Array.isArray(value) && value.length > 0) {
      updateFilter(column.field, client => {
        if (!isNaN(parseInt(value[0]))) {
          value = value.map(i => parseInt(i))
        }

        return value.includes(client[column.field])
      })
    } else {
      updateFilter(column.field, value)
    }

    bodyProps.onFilterChanged(columnId, value)
  }

  return (
    <>
      <div style={{ maxWidth: '100%' }}>
        <MaterialTable
          columns={columns}
          data={data}
          options={{
            grouping: true,
            search: true,
            filtering: true,
            paging: true,
            pageSize: 100,
            pageSizeOptions: [100, 500, 1000],
            columnsButton: true,
          }}
          title="Klienti"
          localization={{
            grouping: {
              placeholder: 'Přetáhni zde záhlaví sloupce pro seskupení',
            },
          }}
          components={{
            Body: bodyProps => (
              <MTableBody {...bodyProps} onFilterChanged={onFilterChanged.bind(null, bodyProps)} />
            ),
          }}
          detailPanel={({ rowData }) => (
            <ClientDetailPanel
              key={rowData.id}
              data={rowData}
              instanceDataKeys={instanceDataKeys}
              instances={instances}
            />
          )}
        />
      </div>
    </>
  )
}

function areEqual(prevProps, nextProps) {
  return prevProps.data == nextProps.data
}

export default memo(ClientsTable, areEqual)
